<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage inventory item</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Inventory
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addInventoryItemm" v-if="checkIsAccessible('inventory-item', 'list')">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add inventory item
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="3">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title" outlined clearable
                                        v-on:keyup.enter="searchInventoryItems()" dense
                                        @input="search.title = $event !== null ? $event : ''"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-autocomplete
                                        :items="addressBooks"
                                        outlined 
                                        label="Contact"
                                        dense
                                        clearable
                                        @input="search.address_book_id = $event !== null ? $event : ''"
                                        v-model="search.address_book_id"
                                        item-text="full_address"
                                        v-on:keyup.enter="searchInventoryItems()"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-autocomplete
                                        :items="categories"
                                        outlined 
                                        label="Category"
                                        dense
                                        clearable
                                        @input="search.category_id = $event !== null ? $event : ''"
                                        v-model="search.category_id"
                                        item-text="title"
                                        v-on:keyup.enter="searchInventoryItems()"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-col cols="12" md="3">
                                    <v-btn :loading="loading" @click.prevent="searchInventoryItems()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Contact</th>
                                        <th class="px-3 wrap-column">Category</th>
                                        <th class="px-3 wrap-column">More</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="inventoryItems.length > 0" v-for="(item, index) of inventoryItems" :key="index">
                                        <td class="px-3 wrap-column">
                                            {{ item.title }}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.address_book_full_address ? item.address_book_full_address : 'NA'}}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.category_title ? item.category_title : 'NA'}}
                                        </td>
                                        <td class="px-3 pb-4 pt-2 wrap-column">
                                            <div>
                                                <b>Quantity: </b>
                                                {{ item.qty ? item.qty : '-'}}
                                            </div>
                                            <div>
                                                <b>Reorder level: </b>
                                                {{ item.reorder_level ? item.reorder_level : '-'}}
                                            </div>
                                            <div>
                                                <b>Assigned quantity: </b>
                                                {{ item.assigned_qty }}
                                            </div>

                                            <div class="mt-2">
                                                <span 
                                                    class="badge badge-success text-lg "
                                                    v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                    {{ item.is_active ? 'Active' : 'Inactive' }}
                                                </span>
                                            </div>
                                            
                                            <div class="mt-3">
                                                <span 
                                                    class="badge badge-success text-lg"
                                                    v-bind:class="{ 'badge-success': item.is_assignable, 'badge-danger': !item.is_assignable }">
                                                    {{ item.is_assignable ? 'Assignable' : 'Not assignable' }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('inventory-item', 'edit')">
                                                        <a href="#" class="navi-link" @click="editInventoryItem(item.id)" >
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="item.can_delete && checkIsAccessible('inventory-item', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteInventoryItem(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="inventoryItems.length == 0">
                                        <td class="text-center  px-3" colspan="7">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="inventoryItems.length > 0" 
                                @input="getInventoryItems" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-app>
</template>

<script>
import InventoryItemService from "@/core/services/inventory-item/InventoryItemService";
import CategoryService from "@/core/services/category/CategoryService"; 
import AddressBookService from "@/core/services/address/AddressBookService"; 

const inventoryItem = new InventoryItemService();
const category = new CategoryService();
const addressBook = new AddressBookService();

export default {
    data() {
        return {
            search: {
                title: '',
                address_book_id: null,
            },
            loading: false,
            inventoryItems: [],
            page: null,
            perPage: null,
            total: null,
            addressBooks: [],
            categories: []
        }
    },
    methods: {
        searchInventoryItems(){
            this.page = 1;
            this.getInventoryItems();
        },  
        getInventoryItems() {
            this.loading = true;
            inventoryItem
                .paginate(this.search, this.page)
                .then(response => {
                    this.inventoryItems = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addInventoryItemm() {
            this.$router.push({
                name: 'create-inventory-item'
            });
        },
        editInventoryItem(id) {
            this.$router.push({
                name: 'edit-inventory-item',
                params: {
                    inventoryItemId: id
                }
            });
        },
        deleteInventoryItem(id){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        inventoryItem
                        .delete(id)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getInventoryItems();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        getCategories() {
            category
            .all()
            .then(response => {
                this.categories = response.data.categories;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        getAddressBooks() {
            addressBook
            .all()
            .then(response => {
                this.addressBooks = response.data.addressBooks;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
    },
    mounted() {
        this.getInventoryItems();
        this.getCategories();
        this.getAddressBooks();
    }
}
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>